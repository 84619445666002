<template>
  <div class="Title">
    <h2 class="title" v-html="text"></h2>
  </div>
</template>

<script>
export default {
  name: "LargeTitle",
  props: {
    text: String,
  },
};
</script>

<style scoped>
h2.title {
  font-size: 5rem;
  margin-bottom: 0.1rem;
}
</style>
