<template>
  <div class="PDFScreen">
    <div v-if="isLoading" class="loading">
      <div class="loader"></div>
    </div>
    <div>
      <vue-pdf-embed
        ref="pdfRef"
        :source="src"
        @rendered="loadDocument"
        />
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "PDFScreen",
  components: {
    VuePdfEmbed,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    loadDocument(args) {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/skin.scss";

.hidden {
  display: none;
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border-left: 0.8em solid $primary;
  animation: spin 1.1s infinite linear;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>