<template>
  <div class="component content NPSRating">
    <b-slider
      size="is-large"
      @change="setInputValue"
      @dragging="changeNegativeBackground"
      :min="0"
      :max="10"
      :custom-formatter="(val) => customFormatter(val)"
      indicator
      rounded
      :tooltip="false"
    >
      <template v-for="val in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
        <b-slider-tick :value="val" :key="val">{{ val }}</b-slider-tick>
        <div class="negative-background" :key="'negative-' + val"></div>
      </template>
    </b-slider>
  </div>
</template>

<script>
export default {
  name: "NPSRating",
  props: {
    inputId: String,
    required: Boolean,
  },
  mounted: function () {
    this.$store.commit("setInputValue", {
      inputId: this.inputId,
      value: {
        value: this.value,
      },
    });
    if (this.required) {
      this.$store.commit("setInputValidation", {
        inputId: this.inputId,
        valid: this.isValid(this.value),
      });
    }
    this.changeNegativeBackground(this.value || 0);
  },
  computed: {
    inputValue: {
      get() {
        if (this.$store.state.inputs[this.inputId])
          return this.$store.state.inputs[this.inputId].value;
        return this.value;
      },
      set(value) {
        this.$store.commit("setInputValue", {
          inputId: this.inputId,
          value: {
            value: value,
          },
        });
        if (this.required) {
          this.$store.commit("setInputValidation", {
            inputId: this.inputId,
            valid: this.isValid(value),
          });
        }
      },
    },
  },
  methods: {
    setInputValue(value) {
      this.inputValue = value;
    },
    isValid: (value) => {
      return value != null;
    },
    changeNegativeBackground(value) {
      const negativeBackground = document.querySelector(".negative-background");
      negativeBackground.style.width = `${(10-value) * 10}%`;
    }, 
    customFormatter(value) {
      if (value === 0) return "😞";
      if (value === 1) return "😞";
      if (value === 2) return "😐";
      if (value === 3) return "😐";
      if (value === 4) return "😐";
      if (value === 5) return "😊";
      if (value === 6) return "😊";
      if (value === 7) return "😊";
      if (value === 8) return "😁";
      if (value === 9) return "😁";
      if (value === 10) return "😁";
    },
  },
};
</script>

<style scoped>
#NPSRating > div {
  width: 100%;
}
.NPSRating {
  padding-top: 2rem;
}
::v-deep .b-slider.is-primary .b-slider-track {
  background: linear-gradient(
    90deg,
    #344176 0%,
    #0047ff 50%,
    #00d1ff 100%
  ) !important;
  border-radius: 999px !important;
}
::v-deep .b-slider.is-primary .b-slider-fill {
  background: unset !important;
  border-radius: 999px !important;
}
::v-deep .b-slider-tick-label {
  top: calc(-0.625rem * 2.5 - 16px) !important;
  color: rgba(36, 36, 36, 0.23) !important;
  font-size: 0.9rem !important;
  line-height: 1.6875rem !important;
}
::v-deep .label {
  margin-bottom: 3rem;
}
::v-deep .b-slider .b-slider-tick {
  background: none !important;
}
::v-deep div.is-dragging > div > div > .b-slider-thumb {
  padding: 18px 2px !important;
  border: 1px solid hsl(0deg 0% 71% / 15%);
}
::v-deep div:not(.is-dragging) > div > div > .b-slider-thumb {
  padding: unset !important;
  background-color: unset !important;
  border: unset !important;
}
::v-deep div.is-dragging > div > div > .b-slider-thumb > span {
  font-size: 1.5rem !important;
}
::v-deep div:not(.is-dragging) > div > div > .b-slider-thumb > span {
  font-size: 2rem !important;
}
.negative-background {
  background-color: hsl(0, 0%, 86%);
  position: absolute;
  right: 0;
  height: 0.75rem;
  border-radius: 999px !important;
}

</style>
