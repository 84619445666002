<template>
  <div class="TextArea">
    <div class="field">
      <label class="label" v-if="label">{{ label }}</label>
      <div class="control">
        <div v-if="validEmail" class="error-message">Invalid address!</div>
        <textarea
          @input="validateEmails"
          v-model="inputValue"
          :placeholder="placeholder"
          :style="{
            backgroundColor: validEmail ? '#fad6c9' : 'white',
            border: validEmail ? '2px solid #fad6c9' : '2px solid #ccc',
          }"
          class="textarea"
        ></textarea>
      </div>
    </div>
  </div>
</template>
<script>
import TextArea from "armory-sdk/src/components/TextArea.vue";

export default {
  name: "EmailValidator",
  extends: TextArea,
  props: {
    required: Boolean,
  },
  data() {
    return {
      emails: "",
      validEmail: false,
    };
  },
  methods: {
    isValidEmail(email) {
      if (email === "") return true;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validateEmails(event) {
      this.emails = event.target.value.split(",").map((email) => email.trim());
      this.validEmail = this.emails.some((email) => !this.isValidEmail(email));
      this.$store.commit("setInputValidation", {
        inputId: this.inputId,
        valid: !this.validEmail && this.emails[0].length > 0,
      });
    },
  },
  mounted() {
    this.$store.commit("setInputValidation", {
      inputId: this.inputId,
      valid: !this.validEmail && this.emails != "",
    });
  },
};
</script>

<style>
.error-message {
  color: #dd6734;
  margin-bottom: 0.5em;
}

.invalid {
  TextArea {
    border: 5px solid #dd6734;
    background-color: #dd6734;
  }
}
</style>