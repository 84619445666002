<template>
  <div class="MultiselectDropdown component content" :class="this.customClasses">
    <b-field :label="label">
      <b-dropdown
        v-model="inputValue"
        multiple
        aria-role="list"
        :mobile-modal="false"
        scrollable
        expanded
      >
        <template #trigger>
          <b-button
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <div class="dropdown-text">
              <div>
                <span v-if="inputValue.length === 0">{{ emptyPlaceholder }}</span>
                <span v-else>{{ fullPlaceholder }} ({{inputValue.length}})</span>
              </div>
              <div>
                <font-awesome-icon
                    icon="chevron-down"
                    size="md"
                ></font-awesome-icon>
              </div>
            </div>
          </b-button>
        </template>
        <b-dropdown-item
          v-for="option in options"
          :value="option.value"
          aria-role="listitem"
        >
          <div class="option-row">
            <div class="option-text">
              <span>
                {{ option.text }}
              </span>
            </div>
            <div class="option-checkbox">
              <input type="checkbox" :checked="inputValue.includes(option.value)" />
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </b-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MultiselectDropdown",
  props: {
    inputId: String,
    options: Array,
    emptyPlaceholder: String,
    fullPlaceholder: String,
    label: String,
    required: {
      type: Boolean,
      default: false,
    },
    customClasses: {
      type: Array,
      default: () => [],
    },
  },
  mounted: function () {
    if (
      !this.isPersistentHistory() ||
      !this.$store.state.inputs[this.inputId]
    ) {
      const value = this.options
        .map((option) => {
          return option.preselected ? option.value : null;
        })
        .filter((option) => option != null);
      this.$store.commit("setInputValue", {
        inputId: this.inputId,
        value: {
          value: value,
        },
      });
    }
    if (this.required)
      this.$store.commit("setInputValidation", {
        inputId: this.inputId,
        valid: this.isValid(
          this.value || this.$store.state.inputs[this.inputId].value
        ),
      });
  },
  computed: {
    inputValue: {
      get() {
        if (this.$store.state.inputs[this.inputId])
          return this.$store.state.inputs[this.inputId].value;
        return [];
      },
      set(value) {
        this.$store.commit("setInputValue", {
          inputId: this.inputId,
          value: {
            value: value,
          },
        });
        if (this.required)
          this.$store.commit("setInputValidation", {
            inputId: this.inputId,
            valid: this.isValid(
              this.value || this.$store.state.inputs[this.inputId].value
            ),
          });
      },
    },
  },
  methods: {
    ...mapGetters(["isPersistentHistory"]),
    checked(value) {
      return this.$store.state.inputs[this.inputId]
        ? this.$store.state.inputs[this.inputId].value.includes(value)
        : false;
    },
    isValid: (value) => {
      if (value == null) return false;
      return value.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep div.dropdown, ::v-deep div.dropdown-trigger, ::v-deep button.button {
  max-width: 100%;
  width: 100%;
}

::v-deep .dropdown-trigger > button.button {
  border-radius: 4px !important;
  padding-top: 20.25px !important;
  padding-bottom: 20.25px !important;
  box-shadow: none !important;
}

::v-deep button.button > span {
  width: 100% !important;
}

::v-deep .dropdown-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
}

::v-deep .dropdown-text > div > span {
  font-size: 17px !important;
  font-family: Helvetica, sans-serif !important;
}

::v-deep .dropdown-text > div > svg {
  color: hsl(229, 53%, 53%);
}

::v-deep a.dropdown-item {
  text-decoration: none !important;
  color: rgb(54, 54, 54) !important;
  font-weight: normal;
  white-space: normal;
  padding: 0.5rem 1rem;
}

::v-deep a.dropdown-item.is-active {
  background-color: #dfe9ff !important;
}

::v-deep .dropdown-item > span {
  white-space: pre-wrap;
}
::v-deep .option-row {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

::v-deep .dropdown-content {
  max-height: 12em !important;
}
</style>
