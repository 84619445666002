<template>
  <div class="component content InputWithAddon">
    <div class="field">
      <label class="label" v-if="label">{{ label }}</label>
      <div class="control">
        <input v-model="inputValue" class="username-input input" type="text"/>
        <div class="addon">{{ addon }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseValue from "armory-sdk/src/components/base/BaseValue.vue";

export default {
  name: "InputWithAddon",
  extends: BaseValue,
  props: {
    defaultValue: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    addon: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.inputValue = String(this.defaultValue || "");
  },
};
</script>

<style scoped>
div.control {
  display: flex;
}

input {
  border: none;
  outline: none;
  box-shadow: none;
  border-right: 1px #dcdcdc solid;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;

}

.addon {
  padding-right: 0 !important;
  align-self: center;
  text-align: center;
  text-wrap: nowrap;
  font-weight: bold;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  width: 60px;
}

div.control {
  border: 1px #dcdcdc solid;
  border-radius: 20px;
}
</style>
