<template>
  <div class="component content EmailInputWithAddon">
    <div class="field">
      <label class="label" v-if="label">{{ label }}</label>
      <div class="control">
        <input v-model="username" class="username-input input" />
        <div class="domain">{{ domain }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseValue from "armory-sdk/src/components/base/BaseValue.vue";

export default {
  name: "EmailInputWithAddon",
  extends: BaseValue,
  props: {
    defaultValue: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    domain: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      username: "",
    };
  },
  watch: {
    username() {
      this.inputValue = this.username + this.domain;
    },
  },
  mounted() {
    this.username = this.defaultValue || "";
    this.resizeDomainInput(this.domain);
  },
  methods: {
    isValid: (value) => {
      return value != null && value.split('@')[0].length > 0;
    },
    resizeDomainInput: (domain) => {
      const domainDiv = document.querySelector('div.domain');
      domainDiv.style.width = domain.length + 5 + 'ch';
    },
  },
};
</script>

<style scoped>
div.control {
  display: flex;
}

input {
  border: none;
  outline: none;
  box-shadow: none;
  border-right: 1px #dcdcdc solid;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;

}

.domain {
  padding-right: 0 !important;
  align-self: center;
  text-align: center;
  text-wrap: nowrap;
  font-weight: bold;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

div.control {
  border: 1px #dcdcdc solid;
  border-radius: 20px;
}
</style>
